import React, { useState, useEffect, useMemo } from "react"
import Styles from "../styles/video_comparison_for_webview.module.scss";
import Video from "../components/Video";
import VideoSelectButton from '../components/VideoSelectButton';
import { ActionObjectType } from "../types/WebViewVideoAction";
import { CloseIcon } from "../icons/CloseIcon";


const VideoComparisonForWebView = ( {
    location
}: any ) => {

    const changeHLSFile2Mp4 = ( videoURL: string ) => {
        if ( !videoURL ) return videoURL
        return videoURL.split( "." ).slice( -1 )[ 0 ] === "m3u8" ?
            videoURL.split( '/' ).slice( 0, -1 ).join( '/' ) + ".mp4" :
            videoURL
    }

    const getVideoURLFromLocation = () => {
        try {
            const queryParam = location.search.slice( 1 )
            const video0__ = queryParam.split( '&' )[ 0 ].split( 'video0=' )[ 1 ]
            const video1__ = queryParam.split( '&' )[ 1 ] ?
                queryParam.split( '&' )[ 1 ].split( 'video1=' )[ 1 ] :
                ""
            setVideo0( changeHLSFile2Mp4( video0__ ) )
            setVideo1( changeHLSFile2Mp4( video1__ ) )
        }
        catch ( error ) {
            alert( "エラー:" + error )
        }
    }

    useEffect( () => {
        getVideoURLFromLocation()
    }, [ location ] )

    const [ sync, setSync ] = useState( false )
    const [ action, setAction ] = useState<ActionObjectType | null>( null )
    const [ video0, setVideo0 ] = useState( "" )
    const [ video1, setVideo1 ] = useState( "" )
    const [ video1IsLocalVideo, setVideo1IsLocalVideo ] = useState( false )
    const [ transparent, setTransparent ] = useState( false )

    const fixBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflow = "hidden"
        document.getElementsByTagName( "body" )[ 0 ].style.overflow = "hidden"
    }

    const releaseBackground = () => {
        document.getElementsByTagName( "html" )[ 0 ].style.overflow = "auto"
        document.getElementsByTagName( "body" )[ 0 ].style.overflow = "auto"
    }

    const onChangeVideoFile = async ( URL: string ) => {
        setVideo1IsLocalVideo( true )
        setVideo1( URL )
    }

    const closeVideoComparisonModalVisible = () => {
        const data = { event: "comparisonEnd" }
        window.ReactNativeWebView && window.ReactNativeWebView.postMessage( JSON.stringify( data ) );
    }

    useEffect( () => {
        fixBackground()
        return () => releaseBackground()
    }, [] )

    // syncの場合は、videoは自分自身では動画を更新せずに、dispatchを介して動画を動かす
    const dispatch = ( action: ActionObjectType ) => setAction( action )

    const videos = useMemo( () =>
        <>
            { video0 &&
                <Video
                    originalFile={ video0 }
                    sync={ sync }
                    index={ 0 }
                    action={ action }
                    dispatch={ dispatch }
                    transparent={ transparent }
                    forWebView={ true }
                    forComparison={ true } />
            }
            { video1 ?
                <Video
                    originalFile={ video1 }
                    sync={ sync }
                    setSync={ setSync }
                    index={ 1 }
                    action={ action }
                    dispatch={ dispatch }
                    forWebView={ true }
                    forComparison={ true }
                    isLocalFile={ video1IsLocalVideo } />
                :
                <VideoSelectButton onChangeVideoFile={ onChangeVideoFile } />
            }
        </>
        , [ video0, video1, sync, action, transparent ] )

    return (
        <div className={ `${ Styles.container } ${ "video_comparison_for_webview_container" }` }>
            <div className={ Styles.close_icon } onClick={ () => closeVideoComparisonModalVisible() }>
                <CloseIcon style={ { fontSize: "1.8rem", color: "#fff" } } />
            </div>
            <div className={ `${ Styles.transparent_icon } ${ transparent && Styles.transparent }` } onClick={ () => setTransparent( !transparent ) }>
                <span>透過</span>
            </div>
            { videos }
        </div>
    )
}

export default VideoComparisonForWebView
