import React, { useRef, FC } from "react"
import Styles from "../styles/VideoSelectButton.module.scss";

type Props = {
    onChangeVideoFile: ( URL: string ) => void
}

const VideoSelectButton: FC<Props> = ( {
    onChangeVideoFile
} ) => {

    const fileInputRef = useRef<HTMLInputElement>( null )

    const onChangeFileInput = () => {
        if ( !fileInputRef.current ) return
        const files = fileInputRef.current.files
        if ( !files ) return
        const file = files[ 0 ]
        const URL = window.URL.createObjectURL( file )
        onChangeVideoFile( URL )
    }

    return (
        <div className={ Styles.container }>
            <input ref={ fileInputRef } type="file" id="file" onChange={ () => onChangeFileInput() } />
            <label htmlFor="file">
                端末の動画を表示
			</label>
        </div>
    )
}


export default VideoSelectButton
